import styled from 'styled-components'

import { LazyImage } from 'components/atoms/Image'

import media from 'styles/media'

export const Wrapper = styled.section``

export const TabsHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 700px;
  margin: 0 auto 96px auto;
  ${media.lg.max} {
    align-items: flex-start;
    margin: 0 0 64px 0;
  }
`

export const TabsTitle = styled.h2`
  font-size: 44px;
  line-height: 52px;
  font-weight: 400;
  text-align: center;
  color: ${({ theme }) => theme.colors.white};

  ${media.lg.max} {
    font-size: 30px;
    line-height: 38px;
    text-align: left;
  }
`

export const TabsText = styled.p`
  margin: 24px 0 0 0;
  font-size: 20px;
  line-height: 28px;
  font-weight: 400;
  text-align: center;
  color: ${({ theme }) => theme.colors.white};

  ${media.lg.max} {
    margin: 16px 0 0 0;
    font-size: 18px;
    line-height: 26px;
    text-align: left;
  }
`

export const TabsList = styled.div``

export const TabsItemContent = styled.div`
  margin: 0;
  padding: 0;
  padding: 64px 16px;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  ${media.sm.min} {
    padding: 64px 32px;
  }

  ${media.lg.min} {
    padding: 144px 32px;
  }

  ${media.xxl.min} {
    padding: 180px 64px;
  }

  ${media.lg.max} {
    width: 100%;
    padding: 0;
    padding-left: 0 !important;
  }
`

export const TabsItemTitle = styled.h3`
  font-size: 25px;
  line-height: 31px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.white};
  ${media.lg.max} {
    font-size: 30px;
    line-height: 38px;
    text-align: left;
    font-weight: 400;
  }
`

export const TabsItemText = styled.p`
  margin: 24px 0 0 0;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.white};
  ${media.lg.max} {
    margin: 16px 0 0 0;
    font-size: 18px;
    line-height: 26px;
    text-align: left;
  }
`

export const TabsItemImgWrapper = styled.div`
  position: relative;
  width: 50%;

  ${media.lg.max} {
    width: 100%;
  }
`

export const TabsItemImg = styled(LazyImage)`
  position: absolute;
  right: 0;
  top: 0;
  width: 50vw;
  max-width: 960px;
  height: 100%;

  ${media.lg.max} {
    margin: 0 0 32px 0;
    position: relative;
    width: 100%;
    height: 80vw;
    max-width: unset;
  }
`

export const TabsItem = styled.article`
  display: flex;
  flex-direction: row-reverse;
  align-items: stretch;
  &:nth-child(2n) {
    flex-direction: row;

    ${TabsItemContent} {
      padding-left: 0;
    }

    ${TabsItemImg} {
      right: unset;
      left: 0;
    }
  }

  ${media.lg.max} {
    flex-direction: column-reverse !important;
    margin: 0 0 64px 0;
    &:last-child {
      margin: 0;
    }
  }
`
