import * as React from 'react'
import { PageProps, graphql } from 'gatsby'
import styled from 'styled-components'

import LayoutBasic from 'layouts/LayoutBasic'
import Seo from 'components/atoms/Seo'
import BannerSimple from 'components/organisms/Banner/BannerSimple'
import ContactForm from 'components/modules/ContactForm'
import Container from 'components/atoms/Container'

import imageEmblem from 'assets/images/talaria-emblemat.svg'
import Tabs from 'components/organisms/Tabs'

import useBreakpoint from 'hooks/useBreakpoint'

import media from 'styles/media'

const StyledForm = styled.div`
  position: relative;
  padding: 192px 0;
  background-image: url(${imageEmblem});
  background-size: auto 800px;
  background-repeat: no-repeat;
  background-position: center 120px;

  ${media.lg.max} {
    padding: 96px 0;
    background-size: auto 500px;
  }
`

const StyledFormInner = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  max-width: 570px;
  width: 100%;
  z-index: 2;

  ${media.lg.max} {
    align-items: flex-start;
  }
`

const PageTabs = styled.div`
  margin: 96px 0 0 0;

  ${media.lg.max} {
    margin: 64px 0 0 0;
  }
`

const PageAbout: React.FC<PageProps<Queries.PageAboutQuery>> = ({ data }) => {
  const PAGE = data?.wpPage?.PageAbout!
  const SEO = data?.wpPage?.seo!

  if (!PAGE || !SEO) throw new Error("CMS data didn't load properly")

  const { lg } = useBreakpoint()

  const dataTabsList = PAGE?.tabsList!.map((tab) => ({
    title: tab?.tabsListTitle!,
    text: tab?.tabsListText!,
    image: tab?.tabsListImg?.localFile?.childImageSharp?.gatsbyImageData!,
  }))

  return (
    <LayoutBasic>
      <Seo
        title={SEO?.title!}
        description={SEO?.metaDesc!}
        ogTitle={SEO?.opengraphTitle!}
        ogDescription={SEO?.opengraphDescription!}
        ogImage={SEO?.opengraphImage?.sourceUrl!}
        twitterTitle={SEO?.twitterTitle}
        twitterDescription={SEO?.twitterDescription}
        twitterImage={SEO?.twitterImage?.sourceUrl}
      />
      <BannerSimple
        title={PAGE?.bannerTitle!}
        background={
          lg
            ? PAGE?.bannerBg?.localFile?.childImageSharp?.gatsbyImageData!
            : PAGE?.bannerBgMobile?.localFile?.childImageSharp?.gatsbyImageData!
        }
        high
        align="left"
      />
      <PageTabs>
        <Tabs
          headerTitle={PAGE?.introTitle!}
          headerText={PAGE?.introText!}
          tabs={dataTabsList}
        />
      </PageTabs>
      <StyledForm>
        <Container>
          <StyledFormInner>
            <ContactForm title={PAGE?.formTitle!} titleAlignCenter />
          </StyledFormInner>
        </Container>
      </StyledForm>
    </LayoutBasic>
  )
}

export default PageAbout

export const query = graphql`
  query PageAbout {
    wpPage(slug: { regex: "/o-firmie/" }) {
      PageAbout {
        bannerTitle
        introTitle
        introText
        bannerBg {
          localFile {
            childImageSharp {
              gatsbyImageData(width: 2000)
            }
          }
        }
        bannerBgMobile {
          localFile {
            childImageSharp {
              gatsbyImageData(width: 2000)
            }
          }
        }
        tabsList {
          tabsListText
          tabsListTitle
          tabsListImg {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 1000)
              }
            }
          }
        }
        formTitle
      }
      seo {
        title
        title
        metaDesc
        opengraphDescription
        opengraphTitle
        opengraphImage {
          sourceUrl
        }
        twitterTitle
        twitterDescription
        twitterImage {
          sourceUrl
        }
      }
    }
  }
`
